import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { IconActionPlus, IconGlobalAlarm, IconButton, IconButtonVariant, Size, Space } from '@hse-design/react';
import { colorAccentSlateblue, colorAccentSlateblue4, colorSystemSuccess } from '@hse-design/tokens';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "iconbutton"
    }}>{`IconButton`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { IconButton } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=8164%3A32420'} storybook={'/?path=/story/inputs-iconbutton--icon-button-default'} name={'IconButton'} vueStorybook={'/?path=/story/inputs-iconbutton--icon-button-default'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`IconButton — это кнопка с иконкой. Отличается от `}<a parentName="p" {...{
        "href": "/src-components-clickable-icon"
      }}>{`ClickableIcon`}</a>{` большим размером и функциональностью.`}</p>
    <Playground __position={1} __code={'<div style={{ display: \'flex\' }}>\n  <IconButton icon={IconActionPlus} />\n  <Space size={Size.small_2x} horizontal />\n  <IconButton icon={IconActionPlus} variant={IconButtonVariant.ghost} />\n  <Space size={Size.small_2x} horizontal />\n  <IconButton icon={IconActionPlus} variant={IconButtonVariant.secondary} />\n  <Space size={Size.small_2x} horizontal />\n  <IconButton variant={IconButtonVariant.pagination}>10</IconButton>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      IconActionPlus,
      IconGlobalAlarm,
      IconButton,
      IconButtonVariant,
      Size,
      Space,
      colorAccentSlateblue,
      colorAccentSlateblue4,
      colorSystemSuccess,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex'
      }}>
    <IconButton icon={IconActionPlus} mdxType="IconButton" />
    <Space size={Size.small_2x} horizontal mdxType="Space" />
    <IconButton icon={IconActionPlus} variant={IconButtonVariant.ghost} mdxType="IconButton" />
    <Space size={Size.small_2x} horizontal mdxType="Space" />
    <IconButton icon={IconActionPlus} variant={IconButtonVariant.secondary} mdxType="IconButton" />
    <Space size={Size.small_2x} horizontal mdxType="Space" />
    <IconButton variant={IconButtonVariant.pagination} mdxType="IconButton">
      10
    </IconButton>
  </div>
    </Playground>
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node="?node-id=9552:925" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "размеры"
    }}>{`Размеры`}</h3>
    <p>{`У компонента три размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`, по умолчанию используется `}<inlineCode parentName="p">{`medium`}</inlineCode>{`.`}</p>
    <p>{`Передайте в проп `}<inlineCode parentName="p">{`size`}</inlineCode>{` необходимый размер`}</p>
    <Playground __position={3} __code={'<div style={{ display: \'flex\', \'align-items\': \'center\' }}>\n  <IconButton size={IconButton.Size.small} icon={IconActionPlus} />\n  <Space size={Size.small_x} horizontal />\n  <IconButton size={IconButton.Size.medium} icon={IconActionPlus} />\n  <Space size={Size.small_x} horizontal />\n  <IconButton size={IconButton.Size.large} icon={IconActionPlus} />\n  <Space size={Size.small_x} horizontal />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      IconActionPlus,
      IconGlobalAlarm,
      IconButton,
      IconButtonVariant,
      Size,
      Space,
      colorAccentSlateblue,
      colorAccentSlateblue4,
      colorSystemSuccess,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        'align-items': 'center'
      }}>
    <IconButton size={IconButton.Size.small} icon={IconActionPlus} mdxType="IconButton" />
    <Space size={Size.small_x} horizontal mdxType="Space" />
    <IconButton size={IconButton.Size.medium} icon={IconActionPlus} mdxType="IconButton" />
    <Space size={Size.small_x} horizontal mdxType="Space" />
    <IconButton size={IconButton.Size.large} icon={IconActionPlus} mdxType="IconButton" />
    <Space size={Size.small_x} horizontal mdxType="Space" />
  </div>
    </Playground>
    <h3 {...{
      "id": "варианты-отображения"
    }}>{`Варианты отображения`}</h3>
    <p>{`У IconButton три варианта отображения. Передайте в проп `}<inlineCode parentName="p">{`variant`}</inlineCode>{` необходимый:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`default`}</inlineCode>{` – вариант по умолчанию. Визуально похож на компонент `}<inlineCode parentName="p">{`Button`}</inlineCode>{` с `}<inlineCode parentName="p">{`primary`}</inlineCode>{` вариантом`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ghost`}</inlineCode>{` – вариант с прозрачным фоном. Визуально занимает меньше места, чем `}<inlineCode parentName="p">{`default`}</inlineCode>{` вариант`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`secondary`}</inlineCode>{` – вторичный вариант отображения кнопки. Отличается от `}<inlineCode parentName="p">{`default`}</inlineCode>{` более мягким фоновым цветом`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`pagination`}</inlineCode>{` – вариант для кнопок пагинации. Отличается от `}<inlineCode parentName="p">{`ghost`}</inlineCode>{` варианта цветом текста`}</p>
      </li>
    </ul>
    <Playground __position={4} __code={'<div style={{ display: \'flex\' }}>\n  <IconButton icon={IconActionPlus} />\n  <Space size={Size.small_2x} horizontal />\n  <IconButton icon={IconActionPlus} variant={IconButtonVariant.ghost} />\n  <Space size={Size.small_2x} horizontal />\n  <IconButton icon={IconActionPlus} variant={IconButtonVariant.secondary} />\n  <Space size={Size.small_2x} horizontal />\n  <IconButton variant={IconButtonVariant.pagination}>1</IconButton>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      IconActionPlus,
      IconGlobalAlarm,
      IconButton,
      IconButtonVariant,
      Size,
      Space,
      colorAccentSlateblue,
      colorAccentSlateblue4,
      colorSystemSuccess,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex'
      }}>
    <IconButton icon={IconActionPlus} mdxType="IconButton" />
    <Space size={Size.small_2x} horizontal mdxType="Space" />
    <IconButton icon={IconActionPlus} variant={IconButtonVariant.ghost} mdxType="IconButton" />
    <Space size={Size.small_2x} horizontal mdxType="Space" />
    <IconButton icon={IconActionPlus} variant={IconButtonVariant.secondary} mdxType="IconButton" />
    <Space size={Size.small_2x} horizontal mdxType="Space" />
    <IconButton variant={IconButtonVariant.pagination} mdxType="IconButton">
      1
    </IconButton>
  </div>
    </Playground>
    <h3 {...{
      "id": "состояние-компонента"
    }}>{`Состояние компонента`}</h3>
    <p>{`Кнопка может находиться в состояниях `}<inlineCode parentName="p">{`selected`}</inlineCode>{`, `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` и `}<inlineCode parentName="p">{`inactive`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`selected`}</inlineCode>{` используется для индикации выбранного элемента. Данное состояние работает с вариантами `}<inlineCode parentName="p">{`ghost`}</inlineCode>{` и `}<inlineCode parentName="p">{`pagination`}</inlineCode>{`, использование с вариантом `}<inlineCode parentName="p">{`default`}</inlineCode>{` не предусмотрено`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`disabled`}</inlineCode>{` и `}<inlineCode parentName="p">{`inactive`}</inlineCode>{` для индикации действий, которые не могут быть совершены в данный момент`}</p>
      </li>
    </ul>
    <p><inlineCode parentName="p">{`disabled`}</inlineCode>{` используется, когда пользователь не может перейти к следующему шагу, пока не
совершит определенное действие, а `}<inlineCode parentName="p">{`inactive`}</inlineCode>{` — для кнопки, нажатие на которую
в принципе не может быть выполнено.`}</p>
    <p>{`Для управления состоянием передайте компоненту одноименный проп`}</p>
    <Playground __position={5} __code={'<div style={{ display: \'flex\', alignItems: \'center\' }}>\n  <IconButton icon={IconActionPlus} selected />\n  <Space size={Size.small_x} horizontal />\n  <IconButton icon={IconActionPlus} disabled />\n  <Space size={Size.small_x} horizontal />\n  <IconButton icon={IconActionPlus} inactive />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      IconActionPlus,
      IconGlobalAlarm,
      IconButton,
      IconButtonVariant,
      Size,
      Space,
      colorAccentSlateblue,
      colorAccentSlateblue4,
      colorSystemSuccess,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
    <IconButton icon={IconActionPlus} selected mdxType="IconButton" />
    <Space size={Size.small_x} horizontal mdxType="Space" />
    <IconButton icon={IconActionPlus} disabled mdxType="IconButton" />
    <Space size={Size.small_x} horizontal mdxType="Space" />
    <IconButton icon={IconActionPlus} inactive mdxType="IconButton" />
  </div>
    </Playground>
    <h3 {...{
      "id": "прогресс-загрузки"
    }}>{`Прогресс загрузки`}</h3>
    <p>{`Для отображения прогресса загрузки используйте проп `}<inlineCode parentName="p">{`spinner`}</inlineCode>{`. Возможные значения пропа:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`true`}</inlineCode>{` – отображается бесконечная загрузка (`}<inlineCode parentName="p">{`indeterminate`}</inlineCode>{` состояние `}<a parentName="p" {...{
            "href": "/src-components-spinner"
          }}>{`Spinner`}</a>{`)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`любое число – заполняется свойство `}<inlineCode parentName="p">{`value`}</inlineCode>{` у `}<a parentName="p" {...{
            "href": "/src-components-spinner"
          }}>{`Spinner`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`done`}</inlineCode>{` – устанавливается состояние `}<inlineCode parentName="p">{`done`}</inlineCode>{` у `}<a parentName="p" {...{
            "href": "/src-components-spinner"
          }}>{`Spinner`}</a></p>
      </li>
    </ul>
    <Playground __position={6} __code={'<div style={{ display: \'flex\', \'align-items\': \'center\' }}>\n  <IconButton spinner={15} icon={IconActionPlus} />\n  <Space size={Size.small_x} horizontal />\n  <IconButton spinner={50} icon={IconActionPlus} />\n  <Space size={Size.small_x} horizontal />\n  <IconButton spinner={true} icon={IconActionPlus} />\n  <Space size={Size.small_x} horizontal />\n  <IconButton spinner={\'done\'} icon={IconActionPlus} />\n  <Space size={Size.small_x} horizontal />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      IconActionPlus,
      IconGlobalAlarm,
      IconButton,
      IconButtonVariant,
      Size,
      Space,
      colorAccentSlateblue,
      colorAccentSlateblue4,
      colorSystemSuccess,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        'align-items': 'center'
      }}>
    <IconButton spinner={15} icon={IconActionPlus} mdxType="IconButton" />
    <Space size={Size.small_x} horizontal mdxType="Space" />
    <IconButton spinner={50} icon={IconActionPlus} mdxType="IconButton" />
    <Space size={Size.small_x} horizontal mdxType="Space" />
    <IconButton spinner={true} icon={IconActionPlus} mdxType="IconButton" />
    <Space size={Size.small_x} horizontal mdxType="Space" />
    <IconButton spinner={'done'} icon={IconActionPlus} mdxType="IconButton" />
    <Space size={Size.small_x} horizontal mdxType="Space" />
  </div>
    </Playground>
    <h3 {...{
      "id": "цвета-кнопки-и-иконки"
    }}>{`Цвета кнопки и иконки`}</h3>
    <p>{`У IconButton есть возможность изменить цвет фона и самой иконки:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`цвет фона меняется с помощью пропа `}<inlineCode parentName="p">{`color`}</inlineCode>{`. В вариантах `}<inlineCode parentName="p">{`ghost`}</inlineCode>{` и `}<inlineCode parentName="p">{`pagination`}</inlineCode>{` фон меняется только в состоянии `}<inlineCode parentName="p">{`selected`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`цвет иконки меняется пропом `}<inlineCode parentName="p">{`iconColor`}</inlineCode></p>
      </li>
    </ul>
    <p>{`Значением пропов может быть любой `}<inlineCode parentName="p">{`css`}</inlineCode>{` цвет. Рекомендуем использовать цвета из `}<a parentName="p" {...{
        "href": "/src-tokens-color"
      }}>{`токенов цветов`}</a></p>
    <Playground __position={7} __code={'<div>\n  <div style={{ display: \'flex\', alignItems: \'center\' }}>\n    <IconButton icon={IconActionPlus} color={colorSystemSuccess} />\n    <Space size={Size.small_2x} horizontal />\n    <IconButton icon={IconActionPlus} iconColor={colorAccentSlateblue4} />\n  </div>\n  <Space vertical size={Size.small_2x} />\n  <div style={{ display: \'flex\', alignItems: \'center\' }}>\n    <IconButton\n      icon={IconActionPlus}\n      variant={IconButtonVariant.ghost}\n      iconColor={colorAccentSlateblue}\n    />\n    <Space size={Size.small_2x} horizontal />\n    <IconButton\n      icon={IconActionPlus}\n      variant={IconButtonVariant.ghost}\n      color={colorSystemSuccess}\n      iconColor={colorAccentSlateblue4}\n      selected={true}\n    />\n  </div>\n  <Space vertical size={Size.small_2x} />\n  <div style={{ display: \'flex\', alignItems: \'center\' }}>\n    <IconButton\n      icon={IconActionPlus}\n      variant={IconButtonVariant.secondary}\n      iconColor={colorAccentSlateblue}\n    />\n    <Space size={Size.small_2x} horizontal />\n    <IconButton\n      icon={IconActionPlus}\n      variant={IconButtonVariant.secondary}\n      color={colorSystemSuccess}\n      iconColor={colorAccentSlateblue4}\n      selected={true}\n    />\n  </div>\n  <Space vertical size={Size.small_2x} />\n  <div style={{ display: \'flex\', alignItems: \'center\' }}>\n    <IconButton variant={IconButtonVariant.pagination}>1</IconButton>\n    <Space size={Size.small_2x} horizontal />\n    <IconButton\n      variant={IconButtonVariant.pagination}\n      color={colorSystemSuccess}\n      selected={true}\n    >\n      2\n    </IconButton>\n  </div>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      IconActionPlus,
      IconGlobalAlarm,
      IconButton,
      IconButtonVariant,
      Size,
      Space,
      colorAccentSlateblue,
      colorAccentSlateblue4,
      colorSystemSuccess,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
      <IconButton icon={IconActionPlus} color={colorSystemSuccess} mdxType="IconButton" />
      <Space size={Size.small_2x} horizontal mdxType="Space" />
      <IconButton icon={IconActionPlus} iconColor={colorAccentSlateblue4} mdxType="IconButton" />
    </div>
    <Space vertical size={Size.small_2x} mdxType="Space" />
    <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
      <IconButton icon={IconActionPlus} variant={IconButtonVariant.ghost} iconColor={colorAccentSlateblue} mdxType="IconButton" />
      <Space size={Size.small_2x} horizontal mdxType="Space" />
      <IconButton icon={IconActionPlus} variant={IconButtonVariant.ghost} color={colorSystemSuccess} iconColor={colorAccentSlateblue4} selected={true} mdxType="IconButton" />
    </div>
    <Space vertical size={Size.small_2x} mdxType="Space" />
    <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
      <IconButton icon={IconActionPlus} variant={IconButtonVariant.secondary} iconColor={colorAccentSlateblue} mdxType="IconButton" />
      <Space size={Size.small_2x} horizontal mdxType="Space" />
      <IconButton icon={IconActionPlus} variant={IconButtonVariant.secondary} color={colorSystemSuccess} iconColor={colorAccentSlateblue4} selected={true} mdxType="IconButton" />
    </div>
    <Space vertical size={Size.small_2x} mdxType="Space" />
    <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
      <IconButton variant={IconButtonVariant.pagination} mdxType="IconButton">
        1
      </IconButton>
      <Space size={Size.small_2x} horizontal mdxType="Space" />
      <IconButton variant={IconButtonVariant.pagination} color={colorSystemSuccess} selected={true} mdxType="IconButton">
        2
      </IconButton>
    </div>
  </div>
    </Playground>
    <h3 {...{
      "id": "состояние-нотификации"
    }}>{`Состояние нотификации`}</h3>
    <p>{`Передайте проп `}<inlineCode parentName="p">{`notification`}</inlineCode>{` для сообщения пользователю о наличии нового уведомления`}</p>
    <Playground __position={8} __code={'<IconButton icon={IconGlobalAlarm} notification />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      IconActionPlus,
      IconGlobalAlarm,
      IconButton,
      IconButtonVariant,
      Size,
      Space,
      colorAccentSlateblue,
      colorAccentSlateblue4,
      colorSystemSuccess,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <IconButton icon={IconGlobalAlarm} notification mdxType="IconButton" />
    </Playground>
    <h3 {...{
      "id": "замена-компонента"
    }}>{`Замена компонента`}</h3>
    <p>{`В основе IconButton можно использовать любой компонент - `}<inlineCode parentName="p">{`button`}</inlineCode>{`, `}<inlineCode parentName="p">{`a`}</inlineCode>{`, `}<inlineCode parentName="p">{`Link`}</inlineCode>{` из `}<inlineCode parentName="p">{`react-router`}</inlineCode>{` и т.п.
По-умолчанию используется `}<inlineCode parentName="p">{`button`}</inlineCode>{`.
Чтобы использовать необходимый компонент, передайте его в проп `}<inlineCode parentName="p">{`component`}</inlineCode>{`.`}</p>
    <p>{`Все лишние пропы, переданные в IconButton, будут проброшены на базовый компонент.
Таким образом можно передавать, например атрибут `}<inlineCode parentName="p">{`href`}</inlineCode>{` для `}<inlineCode parentName="p">{`a`}</inlineCode>{`.`}</p>
    <Playground __position={9} __code={'<IconButton\n  icon={IconActionPlus}\n  component={\'a\'}\n  href={\'https://design-system.hse.ru\'}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      IconActionPlus,
      IconGlobalAlarm,
      IconButton,
      IconButtonVariant,
      Size,
      Space,
      colorAccentSlateblue,
      colorAccentSlateblue4,
      colorSystemSuccess,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconButton icon={IconActionPlus} component={'a'} href={'https://design-system.hse.ru'} mdxType="IconButton" />
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={IconButton} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент в соответствии с пропом `}<inlineCode parentName="p">{`component`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      